<template>
  <v-card class="modal-create">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Добавление задания для <br> {{selectedAuto.name}}</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              :items="autoTasks"
              v-model="selectedAutoTask"
              item-text="title"
              item-value="value"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Выберите задание
            </template>
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-autocomplete>

          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              v-model="selectedUser"
              :items="users"
              item-text="name"
              item-value="id"
              placeholder="Выберите ответственного сотрудника"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              clearable
              item-color="green"
              v-if="selectedAutoTask === 'client_responsible_user_set'"
          >
            <template v-slot:label>
              Ответственный
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-11 w-100"
              color="#44D370"
              :items="statuses"
              v-model="selectedStatus"
              item-text="name"
              item-value="id"
              clearable
              item-color="green"
              v-if="selectedAutoTask === 'client_status_change'"
          >
            <template v-slot:label>
              Статус
            </template>
            <template v-slot:append>
              <span class="evi-autocomplete__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="modal-create__color-select">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="modal-create__color-select">
                <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-11 w-100"
              color="#44D370"
              :items="tags"
              v-model="selectedAddTag"
              item-text="name"
              item-value="id"
              clearable
              item-color="green"
              v-else-if="selectedAutoTask === 'client_tag_add'"
          >
            <template v-slot:label>
              Добавить тег
            </template>
            <template v-slot:append>
                      <span class="evi-autocomplete__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="evi-autocomplete__tag mr-2 mt-2"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="evi-autocomplete__tag"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>

          <v-autocomplete
              class="evi-autocomplete w-100"
              color="#44D370"
              :items="tags"
              v-model="selectedRemoveTag"
              item-text="name"
              item-value="id"
              clearable
              item-color="green"
              v-else-if="selectedAutoTask === 'client_tag_remove'"
          >
            <template v-slot:label>
              Удалить тег
            </template>
            <template v-slot:append>
                      <span class="evi-autocomplete__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="evi-autocomplete__tag mr-2 mt-2"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="evi-autocomplete__tag"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>

          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="reminderDays"
              v-else-if="selectedAutoTask === 'reminder_create'"
              type="number"
          >
            <template v-slot:label>
              Установить напоминание через
            </template>
            <template v-slot:append>
              {{returnDaysAnswer(reminderDays)}}
            </template>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {pluralizeWithoutCount} from "../../tools/Helpers";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "CreateAutoTask",
  mixins: [UserAutocompleteRequestsMixin],
  props: {
    selectedAuto: {
      type: Object
    }
  },
  data: () => ({
    selectedAutoTask: null,
    selectedStatus: null,
    selectedRemoveTag: null,
    selectedAddTag: null,
    selectedUser: null,
    reminderDays: 0,
    nameRules: [(v) => !!v || 'Введите название автоматизации'],
  }),
  computed: {
    ...mapState("tags", ["tags",]),
    ...mapState("automations", ["autoTasks",]),
    ...mapState("client-statuses", ["statuses",]),
  },
  methods: {
    ...mapActions({
      editAutomationActions: 'automations/editAutomationActions',
    }),
    create() {
      let payload = {
        type: this.selectedAutoTask,
      };

      if (this.selectedAutoTask === 'client_status_change') {
        payload.target_id = this.selectedStatus;
      } else if (this.selectedAutoTask === 'client_tag_add') {
        payload.target_id = this.selectedAddTag;
      } else if (this.selectedAutoTask === 'client_responsible_user_set') {
        payload.target_id = this.selectedUser;
      } else if (this.selectedAutoTask === 'client_tag_remove') {
        payload.target_id = this.selectedRemoveTag;
      } else if (this.selectedAutoTask === 'reminder_create') {
        payload.value = this.reminderDays.toString();
      }

      this.editAutomationActions({id: this.selectedAuto.id, data: payload}).then(() => {
        this.$emit('update');
        this.closeDialog();
      });
    },
    returnDaysAnswer(day) {
      let daysPluralize = ['день', 'дня', 'дней'];

      return pluralizeWithoutCount(day, daysPluralize);
    },
    closeDialog() {
      this.selectedAutoTask = null;
      this.selectedStatus = null;
      this.selectedAddTag = null;
      this.selectedRemoveTag = null;
      this.reminderDays = 0;
      this.$emit('close');
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style scoped>

</style>
