<template>
  <div class="auto">
    <div class="users__actions" v-if="hasRight('automations.manage')">
      <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="createAutoModal = true">
        Создать автоматизацию
      </v-btn>
    </div>
    <EsFiltration :showByOptions="showByOptions" :itemsLength="tableMeta.total" :showBy="24"
                  @updatePages="getAutomationsPages">
    </EsFiltration>
    <div v-if="loading" class="auto-cards">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          class="auto-card skeleton-card"
          v-for="i in 8" :key="i"
      />
    </div>
    <div v-else class="auto-cards">
      <div class="auto-card" v-for="item in automations" :key="item.id">
        <div class="auto-remove" v-if="hasRight('automations.manage')" @click="openRemoveModal(item)">
          <img :src="require('@/assets/img/common/remove.svg')">
        </div>
        <div class="evi-toolbar__night mb-11" :class="{'active': item.is_active}">
          <v-switch
              :label="item.is_active ? `Автоматизация включена` : 'Автоматизация отключена'"
              v-model="item.is_active"
              :readonly="!hasRight('automations.manage')"
              @change="editAuto(item)"
          ></v-switch>
        </div>
        <v-text-field
            class="evi-text-field mb-5 w-100"
            color="#44D370"
            v-model="item.name"
            :readonly="!hasRight('automations.manage')"
            @change="editAuto(item)"
        >
          <template v-slot:label>
            Название
          </template>
        </v-text-field>
        <v-autocomplete
            class="evi-autocomplete mb-5 w-100"
            color="#44D370"
            :items="scripts"
            v-model="item.scripts"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            multiple
            v-if="hasRight('automations.manage')"
            @change="editAuto(item)"
            return-object
        >
          <template v-slot:label>
            Прикрепить к быстрым фразам
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
        </v-autocomplete>
        <div class="mb-11" v-else>
          <h4 class="mb-6">Быстрые фразы:</h4>
          <div class="auto-tasks w-100" v-if="item.scripts.length">
            <div class="auto-tasks__item d-flex justify-space-between w-100 align-center mb-4" v-for="t in item.scripts" :key="t.id">
              <span class="auto-tasks__type">{{ t.name }}</span>
            </div>
          </div>
          <small class="grey--text" v-else>
            Быстрые фразы не прикреплены
          </small>
        </div>
        <v-autocomplete
            class="evi-autocomplete w-100 mb-11"
            color="#44D370"
            :items="localMailers(item.mailers)"
            v-model="item.mailers"
            item-text="name"
            item-value="id"
            :loading="mailerLoading"
            @focus="loadMailers"
            :search-input.sync="mailerSearch"
            @input.native="searchMailer(mailerSearch)"
            clearable
            item-color="green"
            multiple
            v-if="hasRight('automations.manage')"
            @change="editAuto(item)"
            return-object
        >
          <template v-slot:label>
            Прикрепить к рассылкам
          </template>
          <template v-slot:append-item>
            <div v-intersect="mailerIntersect"/>
          </template>
          <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-autocomplete>
        <div class="mb-11" v-else>
          <h4 class="mb-6">Рассылки:</h4>
          <div class="auto-tasks w-100" v-if="item.mailers.length">
            <div class="auto-tasks__item d-flex justify-space-between w-100 align-center mb-4" v-for="t in item.mailers" :key="t.id">
              <span class="auto-tasks__type">{{ t.name }}</span>
            </div>
          </div>
          <small class="grey--text" v-else>
            Рассылки не прикреплены
          </small>
        </div>
        <h4 class="mb-6">Задания:</h4>
        <div class="auto-tasks mb-11 w-100" v-if="item.actions.length">
          <div class="auto-tasks__item d-flex justify-space-between w-100 align-center mb-4" v-for="t in item.actions"
               :key="t.id">
            <span class="auto-tasks__type">{{ autoTask(t.type) }}</span>
            <div class="auto-tasks__status ml-auto mr-4"
                 v-if="t.type === 'client_status_change' && returnStatus(t.target_id)"
                 :style="{color: `${returnStatus(t.target_id).color}`}">
              {{ returnStatus(t.target_id).name }}
            </div>
            <div class="auto-tasks__tag ml-auto mr-4"
                 v-else-if="(t.type === 'client_tag_add' || t.type === 'client_tag_remove') && returnTag(t.target_id)"
                 :style="{backgroundColor: `${returnTag(t.target_id).color}`, color: `${returnTag(t.target_id).text_color}` }">
              {{ returnTag(t.target_id).name }}
            </div>
            <router-link :to="{name: 'User-Page', params: {id: t.target_id}}" class="auto-tasks__tag ml-auto mr-4"
                 v-else-if="(t.type === 'client_responsible_user_set') && t.target"
                 :title="t.target.name">
              <v-avatar
                  color="#FFFFFF"
                  size="35"
                  class="user-page__avatar mr-3 cursor"
              >
                <img
                    :src="t.target.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
            </router-link>
            <div class="auto-tasks__status ml-auto mr-4" v-else-if="t.type === 'reminder_create'">
              через {{returnDaysAnswer(t.value)}}
            </div>
            <div class="pointer" @click="openRemoveAutoTaskModal(item, t)">
              <v-icon color="#ED4245" v-if="hasRight('automations.manage')">mdi-trash-can-outline</v-icon>
            </div>
          </div>
        </div>

        <small class="grey--text mb-11" v-else>
          Задания не назначены
        </small>
        <div class="add-field pointer mb-11" @click="addAutoTask(item)" v-if="hasRight('automations.manage')">
          <v-icon>mdi-plus</v-icon>
          Добавить задание
        </div>
      </div>
    </div>
    <v-dialog
        v-model="createAutoModal"
        max-width="612px"
    >
      <CreateAuto @update="getAutos" @close="createAutoModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="removeAutoModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление автоматизации
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить автоматизацию?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeAutoModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeAuto"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="removeAutoTaskModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление задания
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить задание?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeAutoTaskModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeAutoTask"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editAutoTasksModal"
        max-width="600"
    >
      <CreateAutoTask :selectedAuto="selectedAuto" @close="editAutoTasksModal = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {hasRight} from '@/utils/access/hasRight';
import EsFiltration from "../../components/сommon/EsTable/EsFiltration";
import CreateAuto from "../../components/automations/CreateAuto";
import CreateAutoTask from "../../components/automations/CreateAutoTask";
import {pluralize} from "../../tools/Helpers";
import {MailerAutocompleteRequestsMixin} from "@/mixins/autocomplete/MailerAutocompleteRequestsMixin";

export default {
  name: "Automations",
  components: {CreateAutoTask, CreateAuto, EsFiltration},
  mixins: [MailerAutocompleteRequestsMixin],
  data: () => ({
    createAutoModal: false,
    removeAutoModal: false,
    removeAutoTaskModal: false,
    editAutoTasksModal: false,
    selectedAuto: null,
    selectedTask: null,
    showByOptions: [
      {
        text: '4',
        counter: 4,
      },
      {
        text: '8',
        counter: 8,
      },
      {
        text: '12',
        counter: 12,
      },
      {
        text: '24',
        counter: 24,
      },
      {
        text: '50',
        counter: 50,
      },
    ],
  }),
  computed: {
    ...mapState("automations", ["automations",]),
    ...mapState("scripts", ["scripts",]),
    ...mapState("common", ["loading", "tableMeta"]),
    ...mapState("tags", ["tags",]),
    ...mapState("automations", ["autoTasks",]),
    ...mapState("client-statuses", ["statuses",]),
    ...mapState("users", ["users",]),
  },
  methods: {
    ...mapActions({
      getAutomationsPages: 'automations/getAutomationsPages',
      getStatuses: 'client-statuses/getStatuses',
      getTags: 'tags/getItemsBy',
      deleteAutomation: 'automations/deleteAutomation',
      editAutomationActions: 'automations/editAutomationActions',
      deleteAutomationTask: 'automations/deleteAutomationTask',
      editAutomation: 'automations/editAutomation',
      getUsers: 'users/getUsersBy',
    }),
    hasRight: hasRight,
    getAutos() {
      this.getAutomationsPages({
        per_page: 8,
        page: 1,
      });
    },
    localMailers (mailers = []) {
      if (!this.mailers.length && mailers) {
        return [...mailers];
      }
      return [...this.mailers, ...mailers];
    },
    autoTask(item) {
      return this.autoTasks.find(i => i.value === item).title;
    },
    returnStatus(item) {
      return this.statuses.find(i => i.id === item);
    },
    returnTag(item) {
      return this.tags.find(i => i.id === item);
    },
    openRemoveModal(item) {
      this.selectedAuto = item;
      this.removeAutoModal = true;
    },
    openRemoveAutoTaskModal(item, task) {
      this.selectedAuto = item;
      this.selectedTask = task;
      this.removeAutoTaskModal = true;
    },
    addAutoTask(item) {
      this.selectedAuto = item;
      this.editAutoTasksModal = true;
    },
    editAuto (item) {
      let scripts = item.scripts.map(i => i.id);
      let mailers = item.mailers?.map(i => i.id);
      this.editAutomation({
        id: item.id,
        name: item.name,
        is_active: item.is_active,
        scripts: scripts,
        mailers: mailers,
      });
    },
    removeAuto() {
      this.deleteAutomation(this.selectedAuto.id).then((response) => {
        if (response) {
          this.getAutos();
          this.removeAutoModal = false;
        }
      });
    },
    removeAutoTask() {
      this.deleteAutomationTask({auto: this.selectedAuto.id, task: this.selectedTask.id}).then((response) => {
        if (response) {
          this.removeAutoTaskModal = false;
        }
      });
    },
    returnDaysAnswer(day) {
      let daysPluralize = ['день', 'дня', 'дней'];

      return pluralize(day, daysPluralize);
    },
  },
  mounted() {
    this.getTags();
    this.getStatuses();
  }
}
</script>

<style lang="scss">
.auto {
  background: #FFFFFF;
  box-shadow: 10px -2px 54px rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding: 60px 48px;
  margin-bottom: 25px;

  .evi-text-field {
    flex-grow: 0;
  }

  .evi-autocomplete {
    flex-grow: 0;
  }

  &-tasks {
    &__tag {
      border-radius: 2px;
      padding: 7px;
      display: inline-block;
      font-size: 14px;
    }

    &__status {
      font-size: 14px;
    }

    &__type {
      font-size: 14px;
      color: $evi-headline-color;
    }
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
  }

  &-card {
    /* white */
    background: #FFFFFF;
    /* shad */
    box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin-right: 52px;
    margin-bottom: 52px;
    padding: 52px;
    width: 580px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .v-input--switch {
      .v-input__slot {
        flex-direction: row-reverse !important;
      }
    }

    .active {
      .v-input--switch__track {
        background: #008A80;
      }

      .v-input--switch__thumb {
        border-color: #008A80;
        color: #FFFFFF !important;
      }
    }
  }

  &-remove {
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #F2F2F2;
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 26px;
    top: 26px;
  }

  .skeleton-card {
    .v-skeleton-loader__list-item {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
</style>
