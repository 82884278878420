<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Создание автоматизации</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field mb-11"
              color="#44D370"
              v-model="name"
              placeholder="Название автоматизации"
              :rules="nameRules"
          >
            <template v-slot:label>
              Название автоматизации
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              :items="scripts"
              v-model="selectedScripts"
              item-text="name"
              item-value="id"
              clearable
              item-color="green"
              multiple
          >
            <template v-slot:label>
              Быстрые фразы
            </template>
            <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              :items="mailers"
              v-model="selectedMailers"
              item-text="name"
              item-value="id"
              :loading="mailerLoading"
              @focus="loadMailers"
              :search-input.sync="mailerSearch"
              @input.native="searchMailer(mailerSearch)"
              clearable
              item-color="green"
              multiple
          >
            <template v-slot:label>
              Рассылки
            </template>
            <template v-slot:append-item>
              <div v-intersect="mailerIntersect"/>
            </template>
            <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {MailerAutocompleteRequestsMixin} from "@/mixins/autocomplete/MailerAutocompleteRequestsMixin";

export default {
  name: "CreateAuto",
  mixins: [MailerAutocompleteRequestsMixin],
  data: () => ({
    valid: true,
    selectedScripts: [],
    selectedMailers: [],
    name: '',
    nameRules: [(v) => !!v || 'Введите название автоматизации'],
  }),
  computed: {
    ...mapState("scripts", ["scripts",]),
  },
  methods: {
    ...mapActions({
      createItem: 'automations/createAutomation',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        let payload = {
          name: this.name,
          scripts: this.selectedScripts,
          mailers: this.selectedMailers,
        };
        this.createItem(payload).then(() => {
          this.$emit('update');
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.name = '';
      this.selectedScripts = [];
      this.selectedMailers = [];
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style scoped>

</style>
