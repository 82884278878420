import {mapActions, mapState} from "vuex";

export var MailerAutocompleteRequestsMixin = {
    data: () => ({
        mailerSearch: "",
        timerId: null,
        page: 0,
        perPage: 20,
        mailerLoading: false,
    }),
    computed: {
        ...mapState("mailers", ['mailers',]),
    },
    methods: {
        ...mapActions({
            getMailers: 'mailers/getMailersBy',
            addMailers: 'mailers/addMailersBy',
        }),
        getMailersBy (search) {
            this.page = 1;
            this.mailerLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getMailers(args).then(() => {
                this.mailerLoading = false;
            });
        },
        addMailersBy (search) {
            this.page++;
            this.mailerLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addMailers(args).then(() => {
                this.mailerLoading = false;
            });
        },
        loadMailers () {
            this.getMailersBy("");
        },
        mailerIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addMailersBy(this.mailerSearch);
            }
        },
        searchMailer (val) {
            if (val) {
                this.page = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getMailersBy(val);
                }, 500);
            }
        },
    },
};
